import React from "react"
import {
  ConnectedSelectField as SelectField,
  SelectFieldProps,
} from "../../FormFields/SelectField"

export default function StoreSelectField({ ...props }: SelectFieldProps) {
  return (
    <SelectField
      name="Where did you purchase your bike?"
      placeholder="Where did you purchase your bike?"
      {...props}
    >
      <option value="bestchoicecc.top">sixthreezero.com</option>
      <option value="Beachbikes.net">Beachbikes.net</option>
      <option value="Firmstrong.com">Firmstrong.com</option>
      <option value="Amazon">Amazon</option>
      <option value="Walmart">Walmart</option>
      <option value="Bed Bath & Beyond">Bed Bath &amp; Beyond</option>
      <option value="Kohl's">Kohl&apos;s</option>
      <option value="Target">Target</option>
      <option value="Woot">Woot</option>
    </SelectField>
  )
}
